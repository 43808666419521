import React from 'react';
import './index.css';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Footer from '../src/components/footer';
import Header from '../src/components/header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Showcase from '../src/components/showcase';
import PortfolioDetails from '../src/components/portfolioDetails';
import CaseStudy from '../src/components/case_study';
function App() {

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Showcase />} />
        <Route path="/portfolio-details/:id" element={<PortfolioDetails/>} />
        <Route path="/case_study/:id" element={<CaseStudy/>} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
