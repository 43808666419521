import React from "react";

function Footer (){
  return (
    <footer id="footer" class="bg-secondary py-4 text-center text-md-start">
		<div class="container">
			<div class="row row-cols-md-2 row-cols-1">
				<div class="col">
					<ul class="list-inline">
						<li class="list-inline-item"><a href="#"><i class="bi bi-skype"></i></a></li>
						<li class="list-inline-item"><a href="#"><i class="bi bi-instagram"></i></a></li>
						<li class="list-inline-item"><a href="#"><i class="bi bi-facebook"></i></a></li>
						<li class="list-inline-item"><a href="#"><i class="bi bi-linkedin"></i></a></li>
						<li class="list-inline-item"><a href="#"><i class="bi bi-envelope-fill"></i></a></li>
					</ul>
					<div>&copy; 2024 All Rights Reserved</div>
				</div>
				<div class="col">
					<ul class="list-unstyled text-md-end">
						<li><a href="mailto:sales@mindcodelab.pro">sales@mindcodelab.pro</a></li>
						<li><a href="tel:+13434530013">+1(343)453-0013</a>, <a href="tel:+919896570012">+91 9896570012</a></li>
						<li><a href="tel:+918847288672">+91 8847288672</a></li>
					</ul>
				</div>
			</div>
		</div>
    </footer>

  );
};

export default Footer;
