import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import portfolioImage from "../assets/images/portfolio.jpg";
import abbeyLogo from "../assets/images/abbey.png";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
function Showcase() {
  //Fetching Data From Api


  const [project, setProject] = useState([]);
  const getProject = () => {
    axios
      .get("https://mindcoadmin.v4masters.com/public/index.php/projectdetail")
      .then((response) => {
        console.log(response.data);
        const projectsArray = Object.values(response.data);
        setProject(projectsArray);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getProject();
  }, []);

  return (
    <main>
      <div class="container">
        {console.log(project)}
        <section id="showcase" class="text-center py-5">
          <div class=" mx-auto">
            <h1 class="text-primary mt-5">
              PROJECTS <span class="text-dark d-block">SHOWCASE</span>
            </h1>
            <div class="border-bottom border-dark border-2 mx-auto mt-4 mb-5 sep-line"></div>
            <p>
              Below is a list of projects we have done. If you have any
              questions or would like to chat about other things we worked on,
              feel free to contact us.
            </p>
            <a href="/#" class="btn btn-primary btn-lg mt-4 rounded-pill">
              Contact Us
            </a>
          </div>
        </section>
        <section id="portfolio" class="py-5">
          <div class="bg-black rounded-5 p-4 p-lg-5 border-top border-2 border-dark">
            <a
              href="#projects"
              class="bg-white rounded-circle text-center d-block mx-auto shadow-lg mb-5 btm-arrow"
            >
              <i class="bi bi-arrow-down fs-2 fw-bold text-black"></i>
            </a>
            <div
              id="projects"
              class="row row-cols-lg-3 row-cols-sm-2 row-cols-1 g-4"
            >
              {project.map((item) => (
                <div class="col" key={item.id}>
                  <div class="card border-0 rounded-5 h-100">
                    <div class="card-img rounded-5 overflow-hidden position-relative">
                      <img
                        src={`https://smarto.s3.ap-south-1.amazonaws.com/portfolio/${item.project_img}`}
                        alt="Abbey"
                        class="d-block mx-auto img-fluid"
                      />
                      <div class="position-absolute top-50 start-50 translate-middle partner-logo">
                        <img
                          src={`https://smarto.s3.ap-south-1.amazonaws.com/portfolio/${item.project_logo}`}
                          alt="Abbey Logo"
                          class="d-block mx-auto img-fluid"
                        />
                      </div>
                    </div>
                    <h3 class="card-header bg-transparent border-0 mt-4 px-4 py-0">
                      {item.project_title}
                    </h3>
                    <div class="card-body px-4 fw-medium my-1 py-0">{item.sub_title}</div>
                    <div className="card-footer d-flex justify-content-between bg-transparent border-0 fs-6 d-flex p-4 pt-0 my-2 mt-4">
                      <div className="">
                        <div className="fs-5 fw-medium">Tools Used</div>
                        <div className="fs-6">{item.tool_used}</div>
                      </div>
                      <div className="rounded-circle">
                        <Link
                          to={`/portfolio-details/${item.id}`}
                          class="ms-auto"
                        >
                          <i class="bi bi-arrow-up-right btn btn-black rounded-circle fs-3"></i>
                        </Link>
                      </div>
                    </div>
                    {/* <div class="card-footer bg-transparent border-0 fs-6 d-flex p-4 pt-0">
                      <span>
                        <h5 class="mb-0">Tools Used</h5>
                        {item.tool_used}
                      </span>
                      <Link
                        to={`/portfolio-details/${item.id}`}
                        class="ms-auto"
                      >
                        <i class="bi bi-arrow-up-right btn btn-black rounded-circle"></i>
                      </Link>
                    </div> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section
          id="lets-talk"
          class="bg-primary p-5 mb-5 rounded-5 text-white position-relative"
        >
          <h2>
            DO YOU NEED ANY DESIGN ? <span class="d-block">LET'S TALK</span>
          </h2>
          <a href="/#" class="btn btn-light btn-lg mt-4 rounded-pill">
            Contact Us
          </a>
        </section>
      </div>
    </main>
  );
}

export default Showcase;
