/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import logo from "../assets/images/logo.png";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
function Header() {
  const [activeLink, setActiveLink] = useState("All"); // State to track active link

  // Function to handle click event and update active link
  const handleClick = (category) => {
    setActiveLink(category);
  };

  return (
    <>
      <a href="/#" id="scroll" class="bg-primary" style={{ display: "none" }}>
        <i class="bi bi-arrow-up fs-3 fw-bold text-light"></i>
      </a>
      <header id="header" class="text-sm-center">
        <div class="container position-relative">
          <div class="bg-white shadow-sm logo">
            <img src={logo} alt="logo" />
          </div>
          <div
            class="position-absolute top-50 start-100 translate-middle bg-primary rounded-circle d-flex justify-content-center align-items-center toggle-btn"
            data-bs-toggle="offcanvas"
            data-bs-target="#nav"
            id="nav-toggle"
          >
            <span></span>
          </div>
        </div>
        <div class="offcanvas offcanvas-end" id="nav">
          <div class="offcanvas-header">
            <h1 class="offcanvas-title">MENU</h1>
            <button
              type="button"
              class="btn-close position-absolute text-reset text-white bg-white"
              data-bs-dismiss="offcanvas"
            ></button>
          </div>
          <div class="offcanvas-body text-start">
            <p
              className={`border-0 border-1 border-bottom my-1`}
            >
              <a
                href="#"
                className={`${
                  activeLink === "All" ? "active" : "" } d-block  text-decoration-none w-100 rounded-2 text-black h-100 p-2`}
                onClick={() => handleClick("All")}
              >
                All
              </a>
            </p>
            <p
              className={`  border-0 border-1 border-bottom my-1 `}
            >
              <a
                href="#"
                className={`${
                  activeLink === "Android Apps" ? "active" : "" } d-block  text-decoration-none w-100 rounded-2 text-black h-100 p-2`}
                onClick={() => handleClick("Android Apps")}
              >
                Android Apps
              </a>
            </p>
            <p
              className={` border-0 border-1 border-bottom my-1`}
            >
              <a
                href="#"
                className={`${
                  activeLink === "IOS Apps" ? "active" : "" } d-block  text-decoration-none w-100 rounded-2 text-black h-100 p-2`}
                onClick={() => handleClick("IOS Apps")}
              >
                IOS Apps
              </a>
            </p>
            <p
              className={`  border-0 border-1 border-bottom my-1 `}
            >
              <a
                href="#"
                className={`${
                  activeLink === "Web Design" ? "active" : "" } d-block  text-decoration-none w-100 rounded-2 text-black h-100 p-2`}
                onClick={() => handleClick("Web Design")}
              >
                Web Design
              </a>
            </p>
            <p
              className={` border-0 border-1 border-bottom my-1`}
            >
              <a
                href="#"
                className={`${
                  activeLink === "Web Development" ? "active" : "" } d-block  text-decoration-none w-100 rounded-2 text-black h-100 p-2`}
                onClick={() => handleClick("Web Development")}
              >
                Web Development
              </a>
            </p>
            <p
              className={`  border-0 border-1 border-bottom my-1 `}
            >
              <a
                href="#"
                className={`${
                  activeLink === "UI UX Designs" ? "active" : "" } d-block  text-decoration-none w-100 rounded-2 text-black h-100 p-2`}
                onClick={() => handleClick("UI UX Designs")}
              >
                UI UX Designs
              </a>
            </p>
            <p
              className={` border-0 border-1 border-bottom my-1`}
            >
              <a
                href="#"
                className={`${
                  activeLink === "Digital Marketing" ? "active" : "" } d-block  text-decoration-none w-100 rounded-2 text-black h-100 p-2`}
                onClick={() => handleClick("Digital Marketing")}
              >
                Digital Marketing
              </a>
            </p>
            <p
              className={`  border-0 border-1 border-bottom my-1 `}
            >
              <a
                href="#"
                className={`${
                  activeLink === "Logo Design" ? "active" : "" } d-block  text-decoration-none w-100 rounded-2 text-black h-100 p-2`}
                onClick={() => handleClick("Logo Design")}
              >
                Logo Design
              </a>
            </p>
            <p
              className={` border-0 border-1 border-bottom my-1`}
            >
              <a
                href="#"
                className={`${
                  activeLink === "Branding Design" ? "active" : "" } d-block  text-decoration-none w-100 rounded-2 text-black h-100 p-2`}
                onClick={() => handleClick("Branding Design")}
              >
                Branding Design
              </a>
            </p>
            <p
              className={` border-0 border-1 border-bottom my-1`}
            >
              <a
                href="#"
                className={`${
                  activeLink === "Artificial Intelligence" ? "active" : "" } d-block  text-decoration-none w-100 rounded-2 text-black h-100 p-2`}
                onClick={() => handleClick("Artificial Intelligence")}
              >
                Artificial Intelligence
              </a>
            </p>
            <p
              className={`  border-0 border-1 border-bottom my-1 `}
            >
              <a
                href="#"
                className={`${
                  activeLink === "Graphics" ? "active" : "" } d-block  text-decoration-none w-100 rounded-2 text-black h-100 p-2`}
                onClick={() => handleClick("Graphics")}
              >
                Graphics
              </a>
            </p>
            <div class="offcanvas-footer-sec fs-5 text-start px-3 py-0">
              <p>
                <i class="bi bi-envelope pe-2 "></i>Email Address
              </p>
              <p class=" px-4">
                <a href="mailto:sales@mindcodelab.pro">sales@mindcodelab.pro</a>
              </p>
              <p>
                <i class="bi bi-telephone pe-2"></i>Phone Numbers
              </p>
              <p class=" px-4">
                <a href="tel:+13434530013">+1(343)453-0013</a>|{" "}
                <a href="tel:+919896570012">+91 9896570012</a>
              </p>
              <button
                class="btn btn-secondary btn-lg mt-4 rounded-pill"
                type="button"
              >
                Contact Us
              </button>
            </div>
          </div>
          {/* <div class="offcanvas-footer fs-5 text-start px-3 py-0">
            <p>
              <i class="bi bi-envelope pe-2 "></i>Email Address
            </p>
            <p class=" px-4">
              <a href="mailto:sales@mindcodelab.pro">sales@mindcodelab.pro</a>
            </p>
            <p>
              <i class="bi bi-telephone pe-2"></i>Phone Numbers
            </p>
            <p class=" px-4">
              <a href="tel:+13434530013">+1(343)453-0013</a>|{" "}
              <a href="tel:+919896570012">+91 9896570012</a>
            </p>
            <button
              class="btn btn-secondary btn-lg mt-4 rounded-pill"
              type="button"
            >
              Contact Us
            </button>
          </div> */}
        </div>
      </header>
    </>
  );
}

export default Header;
